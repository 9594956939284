import React from 'react';
import { addHours, isBefore } from 'date-fns';

import {
  MaintenanceInfo,
  sorryApp,
  SorryAppNotice,
  SorryAppStatus,
} from '@neptune/maintenance-info-domain';

// eslint-disable-next-line no-restricted-imports
import { usePolling } from 'common/usePolling';

export function useMaintenanceInfo(
  sorryAppId: string,
  interval: number,
  maintenanceSubject: string,
): MaintenanceInfo | undefined {
  const [sorryAppStatus, setSorryAppStatus] = React.useState<SorryAppStatus>();

  usePolling({
    pollingFunction: async () => await sorryApp.fetchStatus(sorryAppId),
    interval,
    onSuccess: (data: SorryAppStatus) => setSorryAppStatus(data),
    deps: [],
  });

  const maintenanceEntries: MaintenanceInfo[] = React.useMemo(
    () => getMaintenanceInfo(sorryAppStatus?.notices, maintenanceSubject),
    [sorryAppStatus, maintenanceSubject],
  );

  const atMostInFollowing24hrs = (d: Date | number) => isBefore(d, addHours(Date.now(), 24));
  const info = maintenanceEntries.filter(({ time }) => atMostInFollowing24hrs(time))[0];

  return info;
}

function getMaintenanceInfo(
  notices: SorryAppNotice[] = [],
  targetSubject: string,
): MaintenanceInfo[] {
  const maintenanceNotices = notices.filter(({ subject }) => subject === targetSubject);
  const maintenanceInfo = maintenanceNotices.map(
    ({ id, subject, synopsis, began_at, begins_at, updated_at, updates }) => {
      const lastUpdate = updates && updates[updates.length - 1];
      const description = lastUpdate?.content || synopsis;
      const startTime = began_at || begins_at || updated_at;
      const time = startTime > updated_at ? startTime : updated_at;
      const compoundId = [id, lastUpdate?.id].filter(Boolean).join(':');

      return {
        id: compoundId,
        subject,
        description,
        time: new Date(time),
      };
    },
  );
  return maintenanceInfo;
}
